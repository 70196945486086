<template>
  <div>
    <b-modal
      id="modal_detalles_salidas"
      ok-only
      size="xl"
      class="p-5"
      title="Detalles de entrega"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      modal-class="modal-danger"
    >

      <b-row>
        <b-col cols="4">
          <b-card class="mx-0 mb-1">
            <feather-icon
              icon="UserIcon"
              class="text-success"
            /> <span class="font-weight-bold text-success text-align-center"> PACIENTE/PERSONA:</span><br>
            <span class="text-align-center">{{ itemEntrega.cliente.name }}</span>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card class="mx-0 mb-1">
            <feather-icon
              icon="SmileIcon"
              class="text-info"
            /> <span class="font-weight-bold text-info text-align-center"> ADMINISTRATIVO QUE ENTREGÓ:</span><br>
            <span class="text-align-center">{{ itemEntrega.servidor_publico.name }}</span>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card class="mx-0 mb-1">
            <feather-icon
              icon="CalendarIcon"
              class="text-warning"
            /> <span class="font-weight-bold text-warning text-align-center"> FECHA Y HORA DE ENTREGA:</span><br>
            <span class="text-align-center">{{ itemEntrega.created_at }}</span>
          </b-card>
        </b-col>
      </b-row>

      <b-card class="mt-0">
        <feather-icon
          icon="ListIcon"
          class="text-primary"
        />
        <span class="text-primary font-weight-bold mb-1">Productos entregados</span>
        <b-table
          ref="refTablaModalDetalleSalida"
          class="position-relative mt-1"
          show-empty
          head-variant="light"
          hover
          responsive
          :busy.sync="isBusy"
          :items="items"
          :fields="fields_listaLotes"

          :empty-text="'No hay registros para mostrar'"
          :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong> cargando...</strong>
            </div>
          </template>
          <template #cell(index)="row">
            {{ row.index + 1 }}

          </template>
          <template #cell(nombre_producto)="row">
            <b-media vertical-align="center">
              <template #aside>
                <!-- <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(recorteName(row.item.cliente.name))"
              /> -->
                <feather-icon
                  icon="FolderPlusIcon"
                  class="text-secondary"
                  size="16"
                />
              </template>
              <b-link
                class="font-weight-bold d-block text-nowrap text-secondary"
              >
                {{ row.item.nombre_producto }}
              </b-link>
            </b-media>
          </template>
          <!-- column: acciones -->
          <!-- <template #cell(acciones)="row">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="SettingsIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="editarLote(row.item)"
              >
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="eliminarLote(row.item)"
              >
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template> -->
        </b-table>

      </b-card>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="gradient-danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modal_detalles_salidas')"
          >
            Salir <feather-icon
              icon="LogInIcon"
              size="19"
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol,
  BButton, BMedia, BLink,
} from 'bootstrap-vue'

// import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    // BDropdown,
    // BDropdownItem,
    BButton,
    BMedia,
    BLink,
    BRow,
    BCol,
  },

  data() {
    return {
      // idVenta: 0,
      isBusy: false,
      items: [],
      itemEntrega: {
        cliente: { name: '' },
        servidor_publico: { name: '' },
      },
      fields_listaLotes: [
        { key: 'index', label: 'N°', class: 'text-center' },
        { key: 'nombre_producto', label: 'MEDICAMENTO', class: 'text-left' },
        { key: 'cantidad', label: 'CANT. ENTREGADA', class: 'text-center' },
        // { key: 'acciones', label: 'acciones', class: 'text-center' },
      ],
    }
  },
  created() {
    this.$parent.$on('cargarDetallesVenta', this.cargarDetallesVenta)
  },
  methods: {
    cargarDetallesVenta(item) {
      this.itemEntrega = item
      this.items = item.venta_productos
      // console.log('itemEntrega: ', this.itemEntrega)
      /* this.$http.get(`/servicio-salud/farmacia/venta/${this.itemEntrega.id}/producto`).then(res => {
        this.items = res.data
        // console.log('respuesta detalles venta: ', res)
      }) */
    },
    getFecha(createdAt) {
      const date = new Date(createdAt)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const hours = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()

      if (month < 10) {
        return `${day}-0${month}-${year} ${hours}:${minute}:${second}`
      }
      return `${day}-${month}-${year} ${hours}:${minute}:${second}`
    },
  },
}
</script>

<style lang="scss">

</style>
