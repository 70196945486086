<template>
  <div>
    <b-card class="px-2 pt-2 my-1">
      <b-row class="mb-1">
        <b-col cols="12">
          <feather-icon
            icon="SearchIcon"
            class="mr-1 text-info"
          />
          <strong class="text-info">Buscar persona</strong>
          <buscar-persona @cargarPersonaSelect="cargarPersonaSelect" />
        </b-col>
      </b-row>
      <b-card class="border-info">
        <b-row class="mb-2">
          <b-col
            cols="12"
            class="mb-1"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="text-info mr-1"
            />
            <strong class="text-info">Adicionar Producto</strong>
          </b-col>
          <b-col cols="7">
            <feather-icon
              icon="SearchIcon"
              class="mr-1"
            />
            <label for="">Buscar Medicamento</label>
            <buscar-producto @cargarProductoSelect="cargarProductoSelect" />
          </b-col>
          <b-col cols="2">
            <feather-icon
              icon="HashIcon"
              class="mr-1"
            />
            <label for="">Cantidad</label>
            <b-form-input
              v-model="cantidad"
              type="number"
            />
          </b-col>

          <b-col cols="3">
            <b-button
              class="mt-2 btn-block"
              variant="outline-success"
              @click="agregarMedicamento"
            >
              adicionar
              <feather-icon
                icon="PlusSquareIcon"
                class="text-success"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-card>

    <b-card class="mt-0 px-2">
      <b-row>
        <b-col md="12">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <feather-icon
                icon="ListIcon"
                size="19"
                class="text-info"
              />
              <h6 class="mb-1 ml-50 text-info">
                <strong>Lista de medicamentos </strong>
              </h6>
            </div>

          </div>
        </b-col>
      </b-row>
      <b-table
        id="my-table"
        ref="refTablaNuevaSalida"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        responsive
        :busy.sync="isBusy"
        :items="productos"
        :fields="fields"
        sticky-header="350px"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <template
          #cell(cantidad)="row"
        >
          <div class="d-flex">
            <b-form-input
              v-model="arrayCantidad[row.item.producto_id]"
              type="number"
              min="0"
              :max="row.item.stock"
              :value="arrayCantidad[row.item.producto_id]"
              style="width: 70px"
              :disabled="parseInt(row.item.stock) == 0 ? true : false"
              @input="actualizarCantidad(row.index,row.item.producto_id)"
            />
            <span class="ml-1 d-flex align-items-center">
              <feather-icon
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.v-danger
                :title="(parseInt(row.item.cantidad) === 0 ) ? 'Stock o cantidad entregar en 0' : '' || (parseInt(row.item.cantidad) > parseInt(row.item.stock)) ? 'Cant. a entregar es mayor a stock' : ''"
                :icon="(parseInt(row.item.cantidad) === 0 || parseInt(row.item.cantidad) > parseInt(row.item.stock)) ? 'XCircleIcon' : 'CheckCircleIcon'"
                size="22"
                :class="(parseInt(row.item.cantidad) === 0 || parseInt(row.item.cantidad) > parseInt(row.item.stock)) ? 'text-danger' : 'text-success'"
              /></span>
          </div>
        </template>
        <!-- column: acciones -->
        <template #cell(acciones)="row">
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="eliminarProducto(row.index)"
          >
            quitar
          </b-button>

        </template>
      </b-table>

      <b-row>
        <b-col cols="12">
          <b-button
            class="mt-2 btn-block d-flex justify-content-center align-items-center"
            variant="outline-info"
            @click="registrarSalida"
          >
            <feather-icon
              icon="SaveIcon"
              size="20"
              class="text-info mr-1"
            /> Registrar entrega
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BButton, BFormInput, VBTooltip,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import BuscarPersona from './BuscarPersona.vue'
import BuscarProducto from './BuscarProducto.vue'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,

    // externos
    BuscarProducto,
    BuscarPersona,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      personaSelect: '', // de componente buscar persona
      productoSelect: '', // de componente buscar producto
      arrayCantidad: [],

      cliente_id: '',
      atencion_id: '',
      estado_receta: '',

      productos: [], // lista de medicamentos en la tabla

      cantidad: '', // campo cantidad de adicionar medicamento
      itemSeleccionado: '',

      isBusy: false,
    }
  },
  methods: {
    cargarPersonaSelect(datosPersona) {
      this.personaSelect = datosPersona
      this.cliente_id = this.personaSelect.id
      // console.log('cargarPersonaSelect: ', this.personaSelect)
    },
    cargarProductoSelect(datosProducto) {
      this.productoSelect = datosProducto
      // console.log('cargarProductoSelect: ', this.productoSelect)
    },
    agregarMedicamento() {
      if (this.cantidad === '' || this.productoSelect === '' || this.personaSelect === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: 'Tiene campos vacios',
            variant: 'warning',
          },
        })
        return
      }

      // preguntamos si el producto ya existe en lista
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        if (this.productos[index].producto_id === this.productoSelect.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Advertencia!',
              icon: 'BellIcon',
              text: 'El producto ya se encuentra en lista',
              variant: 'info',
            },
          })
          return
        }
      }
      // verificamos si el stock es cero
      if (this.productoSelect.stock === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: 'El producto tiene STOCK 0, no puede ser adicionado en la lista',
            variant: 'warning',
          },
        })
        return
      }
      if (this.cantidad > this.productoSelect.stock) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: 'La cantidad elegida es mayor al STOCK del producto',
            variant: 'warning',
          },
        })
        return
      }
      this.productos.push(
        {
          producto_id: this.productoSelect.id, // item seleccionado es medicamento
          medicamento: this.productoSelect.nombre_corto,
          cantidad: this.cantidad, // cantidad a entregar
          stock: this.productoSelect.stock,
        },
      )
      this.arrayCantidad[this.productoSelect.id] = this.cantidad
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Agregado a la lista!',
          icon: 'CheckIcon',
          text: '',
          variant: 'success',
        },
      })
    },
    eliminarProducto(index) {
      this.productos.splice(index, 1)
    },
    registrarSalida() {
      // pregunto si tengo productos en la lista
      if (this.productos.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Lista vacia!',
            icon: 'BellIcon',
            text: 'No se puede registrar la salida',
            variant: 'danger',
          },
        })
        return
      }
      // Pregunto si tengo en la lista prodcutos con catntidad para entregar igual a 0 (cero)
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        // console.log('for: ', this.productos[index].cantidad)
        // eslint-disable-next-line eqeqeq
        if (this.productos[index].cantidad == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Advertencia!',
              icon: 'BellIcon',
              text: 'Producto(s) con cantidad de entrega en "0" ',
              variant: 'warning',
            },
          })
          return
        }
      }

      // Pregunto si tengo en la lista prodcutos con catntidad mayor al stock
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (this.productos[index].cantidad > this.productos[index].stock) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Advertencia!',
              icon: 'BellIcon',
              text: 'Cantidad a entregar no puede ser mayor al stock disponible',
              variant: 'warning',
            },
          })
          return
        }
      }

      this.estado_receta = 'no existe'

      const data = {
        // codigo: 'codigo salida x',
        estado_receta: this.estado_receta,
        atencion_id: this.atencion_id,
        cliente_id: this.cliente_id, // id es el usuario_id
        servidor_publico_id: '101', // falta regularizar el id del servidor(segun autentificación)
        // total: this.productos.reduce((acumulador, value) => acumulador + parseInt(value.cantidad, 10), 0),
        productos: this.productos,
      }

      this.$http.post('/servicio-salud/farmacia/venta', data)
        .then(() => {
        // console.log('respuesta SALIDA: ', res)
          this.swalSuccess('¡Entrega registrada con éxito!')
          this.$bvModal.hide('modalAgregarSalida')
        }).catch(res => {
          console.log(res)
        })
    },
    actualizarCantidad(index, idProducto) {
      if (this.arrayCantidad[idProducto] === '') {
        this.productos[index].cantidad = 0
      //  this.arrayCantidad[idProducto] = 0
      } else {
        this.productos[index].cantidad = this.arrayCantidad[idProducto]
      }
      // console.log('PRODUCTOS: ', this.productos)
    },

    swalSuccess(texto) {
      this.$swal({
        position: 'top-center',
        icon: 'success',
        title: texto,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
  setup() {
    const fields = [
      { key: 'producto_id', label: 'ID', sortable: true },
      { key: 'medicamento', label: 'MEDICAMENTO', sortable: true },
      { key: 'stock', label: 'STOCK', sortable: true },
      { key: 'cantidad', label: 'CANT.ENTREGAR', sortable: true },
      { key: 'acciones', label: 'ACCIONES', sortable: true },
    ]

    return {
      // Filter
      fields,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
