<template>
  <vue-autosuggest
    ref="autocomplete2"
    v-model="query"
    :suggestions="suggestions"
    :input-props="inputProps"
    :section-configs="sectionConfigs"
    :render-suggestion="renderSuggestion"
    :get-suggestion-value="getSuggestionValue"
    @input="fetchResults"
  />
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import {
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    VueAutosuggest,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      query: '',
      // suggest
      timeout: null,
      ticos: [],
      debounceMilliseconds: 500,
      inputProps: {
        // id: 'autosuggest__input_ajax_',
        placeholder: 'Buscar por nombre',
        class: 'form-control',
        // name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        destinations: {
          limit: 50,
          label: '',
          onSelected: selected => {
            // console.log('item seleccionado:', selected)
            this.query = `${selected.item.nombre_corto} (${selected.item.stock})`
            // this.itemSeleccionado = selected.item
            this.$emit('cargarProductoSelect', selected.item)
            // this.ticos.push(selected.item)
          },
        },
      },
    }
  },
  methods: {
    // suggestions
    renderSuggestion(suggestion) {
      return (
          <div>
            <div class="text-warning font-weight-bold">
              <strong class="mr-1">{suggestion.item.nombre_corto}</strong>
              (<span>{suggestion.item.stock}</span>)
            </div>
            <div>
              <span>{suggestion.item.presentacion.nombre}</span>
            </div>
          </div>
      )
    },
    // eslint-disable-next-line no-unused-vars
    getSuggestionValue(suggestion) {
      /* const { codigo, item } = suggestion
      return codigo === 'hotels' ? item.title : `${item.codigo} ${item.descripcion}` */
    },
    fetchResults() {
      const { query } = this
      if (query.length < 3) {
        return
      }

      const perPage = 50

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const dPromise = this.$http.get('/servicio-salud/farmacia/producto', { params: { filter: query, perPage } })
        dPromise.then(response => {
          console.log('response: ', response)
          this.suggestions = []
          this.selected = null
          const { data } = response.data
          // eslint-disable-next-line no-unused-expressions
          data.length && this.suggestions.push({ name: 'destinations', data })
        })
      }, this.debounceMilliseconds)
    },
  },
  setup() {
    return {

      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
