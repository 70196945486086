<template>
  <div>
    <b-card class="mt-0 px-2">

      <div class="m-2">
        <b-row
          class="mb-3 border-bottom"
        >
          <b-col cols="6">
            <div>
              <feather-icon
                icon="ListIcon"
                size="20"
                class="text-primary"
              /> <span
                class="text-primary"
                style="font-size:17px"
              ><strong>LISTA DE ENTREGAS/SALIDAS - FARMACIA</strong></span>
            </div>

          </b-col>
          <b-col
            cols="6"
            class="mb-1"
          >
            <b-button
              size="sm"
              variant="outline-info"
              class="float-right"
              @click="agregarNuevaSalida"
            >
              <feather-icon
                icon="PlusCircleIcon"
                size="15"
              />
              Agregar nueva entrega
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start m-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="container float-right">
              <b-form-input
                v-model="filter"
                type="search"
                class="d-inline-block m-0"
                placeholder="Buscar..."
                debounce="500"
                size="sm"
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        id="my-table"
        ref="refTablaEntregaMedicamentos"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <template #cell(created_at)="row">
          <feather-icon
            icon="CalendarIcon"
            class="text-primary mr-1"
          />
          <span class="text-primary font-weight-bold">{{ row.item.created_at }}</span>
        </template>
        <template #cell(cliente)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(recorteName(row.item.cliente.name))"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ row.item.cliente.name }}
            </b-link>
          </b-media>
        </template>
        <template #cell(servidor_publico)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(recorteName(row.item.servidor_publico.name))"
              />
            </template>
            <b-link
              class="text-primary font-weight-bold d-block text-nowrap"
            >
              {{ row.item.servidor_publico.name }}
            </b-link>
          </b-media>

        </template>
        <!-- column: acciones -->
        <template #cell(acciones)="row">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="align-middle text-primary"
              />
            </template>
            <b-dropdown-item
              @click="verDetalleSalida(row.item)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Ver detalles</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
    <modal-nueva-salida @actualizar-tabla-ventas="actualizarTabla" />
    <modal-detalle-salida />
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BPagination, BFormInput, BDropdown,
  BDropdownItem, BButton, BMedia, BAvatar, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import ModalNuevaSalida from '@/views/salud/farmacia/salidas-list/ModalNuevaSalida.vue'
import ModalDetalleSalida from '@/views/salud/farmacia/salidas-list/ModalDetalleSalida.vue'

import salidasListStoreModule from './salidasListStoreModule'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    // BBadge,
    // BMedia,
    // BAvatar,
    // BProgress,
    // BCardText,

    // externs
    ModalDetalleSalida,
    ModalNuevaSalida,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 50],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],

    }
  },
  methods: {
    actualizarTabla() {
      this.$refs.refTablaEntregaMedicamentos.refresh()
    },
    agregarNuevaSalida() {
      // this.$emit('cargarDetallesCompra', item.id)
      this.$bvModal.show('modalAgregarSalida')
    },
    verDetalleSalida(item) {
      this.$emit('cargarDetallesVenta', item)
      this.$bvModal.show('modal_detalles_salidas')
    },
    async myProvider(ctx) {
      // console.log('CTX: ', ctx)
      const promise = store.dispatch('salidas-list-store/getSalidasMedicamentos', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // console.log('responseGetIngresosMedicamentos: ', response)
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0
        /*
        items.forEach((value, index) => {
          items[index].fecha_venc_dias = this.fecha_menor_en_dias(value.lotes_disponibles)
        }) */

        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    getFecha(createdAt) {
      const date = new Date(createdAt)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      if (month < 10) {
        return `${day}-0${month}-${year}`
      }
      return `${day}-${month}-${year}`
    },
    getTime(createdAt) {
      const date = new Date(createdAt)
      const hours = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
      return ` ${hours}:${minute}:${second}`
    },
    recorteName(name) {
      let recorte = []
      recorte = name.split(' ')
      return `${recorte[0]} ${recorte[1]}`
    },
  },
  setup() {
    const MEDICAM_INGRESOS_APP_STORE_MODULE_NAME = 'salidas-list-store'

    // Register module
    if (!store.hasModule(MEDICAM_INGRESOS_APP_STORE_MODULE_NAME)) store.registerModule(MEDICAM_INGRESOS_APP_STORE_MODULE_NAME, salidasListStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICAM_INGRESOS_APP_STORE_MODULE_NAME)) store.unregisterModule(MEDICAM_INGRESOS_APP_STORE_MODULE_NAME)
    })

    const fields = [
      { key: 'created_at', label: 'FECHA ENTREGA', sortable: true },
      { key: 'cliente', label: 'PACIENTE', sortable: true },
      { key: 'servidor_publico', label: 'SERVIDOR PÚBLICO', sortable: true },
      { key: 'servidor_publico', label: 'SERVIDOR PÚBLICO', sortable: true },
      { key: 'acciones' },
    ]
    return {
      // Filter
      avatarText,
      fields,
      salidasListStoreModule,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
