<template>
  <div>
    <b-modal
      id="modalAgregarSalida"
      size="xl"
      title="REGISTRAR NUEVA ENTREGA"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      cancel-title="Cerrar"
      modal-class="modal-primary"
    >
      <template #modal-header>

        <h5 class="font-weight-bold text-primary float-left mt-1">
          <strong>REGISTRAR NUEVA SALIDA</strong>
        </h5>
        <!-- <b-badge variant="light-info py-1 px-2 float-right">
          <small><strong class="text-primary">ADMINISTRATIVO: </strong> <span class="text-primary">Lic. Julio Marcos Esteban Vargas</span></small>
        </b-badge> -->

      </template>

      <b-tabs
        align="center"
        pills
        nav-class="nav-pill-info"
        class="mt-2"
      >
        <b-tab active>
          <template #title>
            <feather-icon icon="ClipboardIcon" />
            <span>Entrega según receta</span>
          </template>
          <b-card-text>
            <entrega-segun-receta />
          </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>Entrega personalizada</span>
          </template>
          <b-card-text>
            <entrega-personalizada />
          </b-card-text>
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modalAgregarSalida')"
          >
            cancelar <feather-icon
              icon="LogInIcon"
              size="14"
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip, BModal, BTabs, BTab, BCardText, BButton,
  // BBadge,
} from 'bootstrap-vue'

// import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

import EntregaPersonalizada from './EntregaPersonalizada.vue'
import EntregaSegunReceta from './EntregaSegunReceta.vue'

export default {
  components: {
    BModal,
    BTabs,
    BTab,
    BCardText,
    BButton,
    // BBadge,

    // externos
    EntregaSegunReceta,
    EntregaPersonalizada,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      // componente buscar persona
      personaSelect: '',

      // componente buscar producto
      productoSelect: '',

      checkedMostrarProdRegEntreg: true,
      arrayCantidad: [],
      queryBuscarReceta: '',
      cliente_id: '',
      atencion_id: '',
      estado_receta: '',
      RecetaSelect: [],

      // tabla cargar recetas
      resultSearchReceta: false,
      resultadoRecetas: [],
      fieldsRecetas: [
        { key: 'index', label: 'N°', sortable: true },
        { key: 'servicio_id', label: 'SERVICIO', sortable: true },
        { key: 'paciente', label: 'PACIENTE', sortable: true },
        { key: 'fecha_atencion', label: 'FECHA EMISIÓN', sortable: true },
        { key: 'numero_atencion', label: 'NÚMERO ATENCIÓN', sortable: true },
        { key: 'acciones', label: 'ACCIONES', sortable: true },
      ],
      // variables radio button
      selectedRadioButton: false,
      options: [
        { text: 'Entrega personalizada', value: false },
        { text: 'Entrega según receta', value: true },
      ],

      // variables de add compra
      // codigoCompra: '',
      // proveedor: '',
      // fechaCompra: '',
      // fechaEntrega: '',
      productos: [],

      // variables de add producto
      // fechaVencimiento: '',
      // precioCompra: '',
      cantidad: '',
      itemSeleccionado: '',

      isBusy: false,
      dateDefault: '',
      from: 1,
      to: 1,
      totalRows: 1,

      pageOptions: [5, 10, 15, 50],
      sortBy: 'id',
      sortDesc: true,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],
    }
  },
  methods: {
    cambioChecked() {
      this.checkedMostrarProdRegEntreg = !this.checkedMostrarProdRegEntreg
    },
    cargarPersonaSelect(datosPersona) {
      this.personaSelect = datosPersona
      this.cliente_id = this.personaSelect.id
      // console.log('cargarPersonaSelect: ', this.personaSelect)
    },
    cargarProductoSelect(datosProducto) {
      this.productoSelect = datosProducto
      // console.log('cargarProductoSelect: ', this.productoSelect)
    },
    agregarMedicamento() {
      if (this.cantidad === '' || this.productoSelect === '' || this.personaSelect === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: 'Tiene campos vacios',
            variant: 'info',
          },
        })
        return
      }

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        if (this.productos[index].producto_id === this.productoSelect.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Advertencia!',
              icon: 'BellIcon',
              text: 'El producto ya se encuentra en lista',
              variant: 'info',
            },
          })
          return
        }
      }

      this.productos.push(
        {
          producto_id: this.productoSelect.id, // item seleccionado es medicamento
          medicamento: this.productoSelect.nombre_corto,
          cantidad_receta: this.cantidad,
          cantidad: this.cantidad, // cantidad a entregar
          stock: this.productoSelect.stock,
          // precio_compra: this.precioCompra,
        },
      )
      this.arrayCantidad[this.productoSelect.id] = this.cantidad
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Agregado a la lista!',
          icon: 'CheckIcon',
          text: '',
          variant: 'success',
        },
      })
    },
    eliminarProducto(index) {
      this.productos.splice(index, 1)
    },
    registrarSalida() {
      // pregunto si tengo productos en la lista
      if (this.productos.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Lista vacia!',
            icon: 'BellIcon',
            text: 'No se puede registrar la salida',
            variant: 'danger',
          },
        })
        return
      }
      // Pregunto si tengo en la lista prodcutos con catntidad para entregar igual a 0 (cero)
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        console.log('for: ', this.productos[index].cantidad)
        // eslint-disable-next-line eqeqeq
        if (this.productos[index].cantidad == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Advertencia!',
              icon: 'BellIcon',
              text: 'Producto(s) con cantidad de entrega en "0" ',
              variant: 'warning',
            },
          })
          return
        }
      }

      // Pregunto si tengo en la lista prodcutos con catntidad mayor a lo recetado
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (this.productos[index].cantidad > this.productos[index].cantidad_receta) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Advertencia!',
              icon: 'BellIcon',
              text: 'Cantidad a entregar no puede ser mayor a la cantidad recetada',
              variant: 'warning',
            },
          })
          return
        }
      }

      if (this.selectedRadioButton) { // true : vista de recetas
        this.obtenerEstadoReceta()
      } else {
        this.estado_receta = ''
      }

      const data = {
        // codigo: 'codigo salida x',
        estado_receta: this.estado_receta,
        atencion_id: this.atencion_id,
        cliente_id: this.cliente_id, // id es el usuario_id
        servidor_publico_id: '101', // falta regularizar el id del servidor(segun autentificación)
        // total: this.productos.reduce((acumulador, value) => acumulador + parseInt(value.cantidad, 10), 0),
        productos: this.productos,
      }

      this.$http.post('/servicio-salud/farmacia/venta', data)
        .then(() => {
        // console.log('respuesta SALIDA: ', res)
          this.swalSuccess('Salida registrada con éxito!!')
          this.$bvModal.hide('modalAgregarSalida')
        }).catch(res => {
          console.log(res)
        })
    },
    actualizarCantidad(index, idProducto) {
      if (this.arrayCantidad[idProducto] === '') {
        this.productos[index].cantidad = 0
      //  this.arrayCantidad[idProducto] = 0
      } else {
        this.productos[index].cantidad = this.arrayCantidad[idProducto]
      }

      // console.log('PRODUCTOS: ', this.productos)
      // vconsole.log('arrayCAntidad: ', this.arrayCantidad[idProducto])
    },
    obtenerEstadoReceta() {
      if (this.productos.length !== this.RecetaSelect.length) {
        this.estado_receta = 'entrega incompleta'
        // console.log('entre aqui 1')
        // console.log('prductos lentgh: ', this.productos.length)
        // console.log('recetaSelect length:', this.RecetaSelect.length)
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.productos.length; index++) {
          // eslint-disable-next-line radix
          if (parseInt(this.productos[index].cantidad) !== 0) { // si es == a 0 (cero) el front no dejara registrar la salida
            if (this.productos[index].cantidad < this.productos[index].cantidad_receta) {
              this.estado_receta = 'entrega incompleta'
              // console.log('entre: ', index)
              break
            } else {
              this.estado_receta = 'entrega completa'
            }
          }
        }
      }
    },

    swalSuccess(texto) {
      this.$swal({
        position: 'top-center',
        icon: 'success',
        title: texto,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    buscarReceta() {
      store.dispatch('salidas-list-store/fetchReceta', { query: this.queryBuscarReceta }).then(res => {
        if (res.length === 0) {
          this.resultSearchReceta = false
        } else {
          this.resultadoRecetas = res.data
          this.resultSearchReceta = true
        }
        // console.log('RESULTADO BUSQUEDA: ', res)
      })
    },
    cargarReceta(item) {
      this.RecetaSelect = item.recetas
      // console.log('cargar_receta: ', item)
      this.productos = []
      this.arrayCantidad = []
      this.cliente_id = item.paciente_id
      this.atencion_id = item.id // id : id de atencion

      item.recetas.forEach((value, index) => {
        this.productos.push(
          {
            producto_id: value.medicamento_id, // item seleccionado es medicamento
            medicamento: value.medicamento.nombre_corto,
            cantidad_receta: value.cantidad,
            cantidad: value.cantidad, // cantidad a entregar
            stock: value.medicamento.stock,
          // precio_compra: this.precioCompra,
          },
        )
        if (value.cantidad > value.medicamento.stock) {
          this.arrayCantidad[value.medicamento_id] = value.medicamento.stock
          this.productos[index].cantidad = value.medicamento.stock
        } else {
          this.arrayCantidad[value.medicamento_id] = value.cantidad
        }
      })
    },
  },
  setup() {
    const fields = [
      { key: 'producto_id', label: 'ID', sortable: true },
      { key: 'medicamento', label: 'MEDICAMENTO', sortable: true },
      {
        key: 'cantidad_receta',
        label: 'CANTIDAD RECETA',
        sortable: true,
        /* thStyle: {
          display: 'none',
        }, */
      },
      { key: 'stock', label: 'STOCK', sortable: true },
      { key: 'cantidad', label: 'CANT.ENTREGAR', sortable: true },
      { key: 'acciones', label: 'ACCIONES', sortable: true },
    ]

    return {
      // Filter
      fields,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
