<template>
  <div>
    <b-card
      v-if="!booleanlistaCargada"
      class="pt-2 px-2"
    >
      <b-row class="d-flex justify-content-center mb-1">
        <b-col cols="9">
          <div>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="queryBuscarReceta"
                placeholder="Ingrese DNI o apellidos del paciente"
                @keyup.enter="buscarReceta"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="buscarReceta"
                >
                  Buscar receta
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-table
        v-if="resultSearchReceta"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        responsive
        :items="resultadoRecetas"
        :fields="fieldsRecetas"
        sticky-header="350px"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>

        <template #cell(paciente)="row">
          {{ row.item.usuario_persona.nombre_completo }}
        </template>

        <template #cell(receta)="row">
          <span>{{ row.item.serie }} - {{ row.item.show_numero_receta }}</span>
        </template>

        <template #cell(estado)="row">
          <b-badge
            :variant="row.item.estado === 'pendiente' ? 'success' : 'danger'"
          >
            <span class="text-uppercase">{{ row.item.estado }}</span>
          </b-badge>
        </template>

        <template #cell(acciones)="row">
          <b-button
            size="sm"
            variant="outline-info"
            @click="cargarReceta(row.item)"
          >
            cargar receta

            <feather-icon
              icon="PlayIcon"
              class="text-info"
            />

          </b-button>
        </template>
      </b-table>
      <b-row
        v-else
        class="d-flex justify-content-center mb-1"
      >
        <div>No se encontraron resultados para la busqueda</div>
      </b-row>
    </b-card>

    <b-card
      v-else
      class="pt-1"
    >
      <b-row>
        <b-col
          cols="12"
          class="bg-light-info text-center py-1 mb-1"
        >
          <h5><strong><span class="text-info">RECETA:</span> {{ RecetaSelect.serie }} - <span class="text-danger">{{ RecetaSelect.show_numero_receta }}</span></strong> <span><small>({{ RecetaSelect.usuario_persona.nombre_completo }})</small></span></h5>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-badge
            class="px-2 py-1 btn float-right"
            variant="light-warning"
            @click="volverAlBuscador"
          >
            <feather-icon
              icon="SearchIcon"
              size="30"
              class="text-warning"
            /> volver al buscador
          </b-badge>
          <feather-icon
            icon="UserIcon"
            size="17"
            class="text-warning mr-1"
          />
          <span class="text-warning"><strong>Paciente: </strong> </span>{{ RecetaSelect.usuario_persona.nombre_completo }}
          <b-badge
            variant="light-success"
            @click="volverAlBuscador"
          >
            {{ RecetaSelect.usuario_persona.numero_documento }}
          </b-badge>
          <br>
          <feather-icon
            icon="ThermometerIcon"
            size="17"
            class="text-warning mr-1"
          />
          <span class="text-warning"><strong>Servicio: </strong></span>
          <br>
          <feather-icon
            icon="CalendarIcon"
            size="17"
            class="text-warning mr-1"
          />
          <span class="text-warning"><strong>Fecha emisión: </strong></span> {{ RecetaSelect.created_at }}

        </b-col>
        <b-col md="12">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <feather-icon
                icon="ListIcon"
                size="19"
                class="text-warning"
              />
              <h6 class="mb-1 ml-50 text-warning">
                <strong>Lista de medicamentos a entregar</strong>
              </h6>
            </div>
            <div>
              <b-form-checkbox
                v-model="checkedMostrarProdRegEntreg"
                class="float-right"
                switch
                button-variant="info"
                @change="cambioChecked"
              >
                <small class="text-secondary"> Mostrar entrega al finalizar </small>
              </b-form-checkbox>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-table
        id="my-table"
        ref="refTablaNuevaSalida"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        responsive
        :busy.sync="isBusy"
        :items="productos"
        :fields="fields"
        sticky-header="350px"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <template
          #cell(cantidad)="row"
        >
          <div class="d-flex">
            <b-form-input
              v-model="arrayCantidad[row.item.producto_id]"
              type="number"
              min="0"
              :max="row.item.cantidad_receta"
              :value="arrayCantidad[row.item.producto_id]"
              style="width: 50px"
              :disabled="parseInt(row.item.stock) == 0 ? true : false"
              @input="actualizarCantidad(row.index,row.item.producto_id)"
            />
            <span class="ml-1 d-flex align-items-center">
              <feather-icon
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.v-danger
                :title="(parseInt(row.item.cantidad) === 0 ) ? 'Stock o cantidad a entregar en 0' : '' || (parseInt(row.item.cantidad) > parseInt(row.item.cantidad_receta)) ? 'Cant. a entregar es mayor a cant. receta' : ''"
                :icon="(parseInt(row.item.cantidad) === 0 || parseInt(row.item.cantidad) > parseInt(row.item.cantidad_receta)) ? 'XCircleIcon' : 'CheckCircleIcon'"
                size="22"
                :class="(parseInt(row.item.cantidad) === 0 || parseInt(row.item.cantidad) > parseInt(row.item.cantidad_receta)) ? 'text-danger' : 'text-success'"
              /></span>
          </div>
        </template>
        <!-- column: acciones -->
        <template #cell(acciones)="row">
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="eliminarProducto(row.index)"
          >
            quitar
          </b-button>

        </template>
      </b-table>

      <b-row>
        <b-col cols="12">
          <b-button
            class="mt-2 btn-block d-flex justify-content-center align-items-center"
            variant="outline-info"
            @click="registrarSalida"
          >
            <feather-icon
              icon="SaveIcon"
              size="20"
              class="text-info mr-1"
            /> Registrar entrega
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BButton, BFormInput, VBTooltip, BInputGroup,
  BInputGroupAppend, BInputGroupPrepend, BFormCheckbox, BBadge,
} from 'bootstrap-vue'

// import store from '@/store'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BBadge,
    // externos
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      booleanlistaCargada: false,

      checkedMostrarProdRegEntreg: true, // modal que muestra los productos entregados
      arrayCantidad: [],
      queryBuscarReceta: '',
      cliente_id: '',
      atencion_id: '',
      estado_receta: '',
      RecetaSelect: [],

      // tabla cargar recetas
      resultSearchReceta: false,
      resultadoRecetas: [],
      fieldsRecetas: [
        { key: 'index', label: 'N°' },
        { key: 'receta', label: 'serie - numero' },
        // { key: 'servicio_id', label: 'servicio' },
        { key: 'paciente', label: 'paciente' },
        { key: 'created_at', label: 'fecha emisión' },
        { key: 'estado', label: 'estado entrega' },
        { key: 'acciones', label: 'ACCIONES' },
      ],

      productos: [],
      // cantidad: '',

      isBusy: false,
      /* dateDefault: '',
      from: 1,
      to: 1,
      totalRows: 1,

      pageOptions: [5, 10, 15, 50],
      sortBy: 'id',
      sortDesc: true, */
      // sortDirection: 'desc',
      /* filter: '',
      filterOn: [], */
    }
  },
  methods: {
    cambioChecked() {

    },
    eliminarProducto(index) {
      this.productos.splice(index, 1)
    },
    registrarSalida() {
      // pregunto si tengo productos en la lista
      if (this.productos.length === 0) {
        this.toastError('No se puede registrar la salida')
        return
      }
      // Pregunto si tengo en la lista prodcutos con catntidad para entregar igual a 0 (cero)
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        // console.log('for: ', this.productos[index].cantidad)
        // eslint-disable-next-line eqeqeq
        if (this.productos[index].cantidad == 0) {
          this.toastAlert('Producto(s) con cantidad de entrega en "0"')
          return
        }
      }

      // Pregunto si tengo en la lista prodcutos con cantidad mayor a lo recetado
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (this.productos[index].cantidad > this.productos[index].cantidad_receta) {
          this.toastAlert('Cantidad a entregar no puede ser mayor a la cantidad recetada')
          return
        }
      }

      this.obtenerEstadoReceta() // obtengo el estado de la receta(estado_receta)

      const data = {
        receta_id: this.receta_id,
        estado_receta: this.estado_receta,
        atencion_id: this.atencion_id,
        cliente_id: this.cliente_id, // id es el usuario_id
        servidor_publico_id: '101', // falta regularizar el id del servidor(segun autentificación)
        // total: this.productos.reduce((acumulador, value) => acumulador + parseInt(value.cantidad, 10), 0),
        productos: this.productos,
      }

      this.$http.post('/servicio-salud/farmacia/venta', data)
        .then(() => {
        // console.log('respuesta SALIDA: ', res)
          this.$emit('actualizar-tabla-ventas')
          this.swalSuccess('¡Entrega registrada con éxito!')
          this.$bvModal.hide('modalAgregarSalida')
        }).catch(error => {
          this.toastError(`${error.response.data.error}`)
        })
    },
    actualizarCantidad(index, idProducto) {
      if (this.arrayCantidad[idProducto] === '') {
        this.productos[index].cantidad = 0
      } else {
        this.productos[index].cantidad = this.arrayCantidad[idProducto]
      }

      // console.log('PRODUCTOS: ', this.productos)
    },
    obtenerEstadoReceta() {
      if (this.productos.length !== this.RecetaSelect.receta_detalles.length) {
        this.estado_receta = 'entrega incompleta'
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.productos.length; index++) {
          // eslint-disable-next-line radix
          if (parseInt(this.productos[index].cantidad) !== 0) { // si es == a 0 (cero) el front no dejara registrar la salida
            if (this.productos[index].cantidad < this.productos[index].cantidad_receta) {
              this.estado_receta = 'entrega incompleta'
              // console.log('entre: ', index)
              break
            } else {
              this.estado_receta = 'entrega completa'
            }
          }
        }
      }
    },

    swalSuccess(texto) {
      this.$swal({
        position: 'top-center',
        icon: 'success',
        title: texto,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    buscarReceta() {
      store.dispatch('salidas-list-store/fetchReceta', { filter: this.queryBuscarReceta })
        .then(response => {
          // console.log(response.data.data)
          if (response.data.data.length === 0) {
            this.resultSearchReceta = false
          } else {
            this.resultadoRecetas = response.data.data
            this.resultSearchReceta = true
          }
          // console.log('RESULTADO BUSQUEDA: ', res)
        })
    },
    cargarReceta(item) {
      this.RecetaSelect = item
      // console.log('cargar_receta: ', item)
      this.productos = []
      this.arrayCantidad = []
      this.cliente_id = item.paciente_id
      this.atencion_id = item.atencion_id // id : id de atencion
      this.receta_id = item.id // id : id de receta

      item.receta_detalles.forEach((value, index) => {
        this.productos.push(
          {
            producto_id: value.medicamento_id, // item seleccionado es medicamento
            medicamento: value.medicamento.nombre_corto,
            cantidad_receta: value.cantidad,
            cantidad: value.cantidad, // cantidad a entregar
            stock: value.medicamento.stock,
          // precio_compra: this.precioCompra,
          },
        )
        if (value.cantidad > value.medicamento.stock) {
          this.arrayCantidad[value.medicamento_id] = value.medicamento.stock
          this.productos[index].cantidad = value.medicamento.stock
        } else {
          this.arrayCantidad[value.medicamento_id] = value.cantidad
        }
      })

      // ocultamos la sección busqueda de recetas
      this.booleanlistaCargada = true
    },
    volverAlBuscador() {
      this.booleanlistaCargada = false
    },
  },
  setup() {
    const fields = [
      { key: 'producto_id', label: 'ID', sortable: true },
      { key: 'medicamento', label: 'MEDICAMENTO', sortable: true },
      {
        key: 'cantidad_receta',
        label: 'CANTIDAD RECETA',
        sortable: true,
      },
      { key: 'stock', label: 'STOCK', sortable: true },
      { key: 'cantidad', label: 'CANT.ENTREGAR', sortable: true },
      { key: 'acciones', label: 'ACCIONES', sortable: true },
    ]

    return {
      // Filter
      fields,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
